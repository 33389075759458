import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {StyledCompanyDetails} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {pushToNextRoute} from "../../utils/pushToNextRoute";
import {GeneralTextField} from "../../components/Inputs";
import {FormControl} from "@material-ui/core";
import CustomSelect from "../../components/Selects";
import {getCompanyListData, getCountryListData, getCurrencyListData} from "./selectors";
import {updateCompanyDetails} from "../../generalSagas/actions";
import {extractNamesArr} from "../../utils/extractNamesArr";
import {companyListAttempt, countryListAttempt, currencyListAttempt} from "./actions";
import {ICompanyTypeState, ICountryDetailsState, ICurrencyDetailsState} from "./model";
import {getCompanyDetailsData} from "../../generalSagas/selectors";
import {ICompanyRegistrationDetails} from "../../@types";

export const CompanyDetails: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const companyRegistrationDetails = useSelector(getCompanyDetailsData);
    const countryList = useSelector(getCountryListData);
    const currencyList = useSelector(getCurrencyListData);
    const companyTypeList = useSelector(getCompanyListData);

    const [companyDetailsState, setCompanyDetailsState] = useState<ICompanyRegistrationDetails>(companyRegistrationDetails);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        countryList.length === 0 && dispatch(countryListAttempt());
        currencyList.length === 0 && dispatch(currencyListAttempt());
        companyTypeList.length === 0 && dispatch(companyListAttempt());
    }, []);

    useEffect(() => {
        if (companyDetailsState && companyDetailsState.companyDetails.name !== '' &&
            companyDetailsState.companyDetails.country.name !== '' &&
            companyDetailsState.companyDetails.currency.name !== '' &&
            companyDetailsState.companyDetails.company.name !== '') {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [companyDetailsState]);

    const onSelectCountryHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        countryList.filter((type: ICountryDetailsState) => type.name === event.target.value).length > 0 &&
        setCompanyDetailsState(prevState => ({
            ...prevState,
            companyDetails: {
                ...prevState.companyDetails,
                country: {
                    countryId: countryList.filter((type: ICountryDetailsState) => type.name === event.target.value)[0].countryId,
                    name: countryList.filter((type: ICountryDetailsState) => type.name === event.target.value)[0].name,
                    code: countryList.filter((type: ICountryDetailsState) => type.name === event.target.value)[0].code
                },
            }
        }))
    };

    const onSelectCurrencyHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        currencyList.filter((type: ICurrencyDetailsState) => type.name === event.target.value).length > 0 &&
        setCompanyDetailsState(prevState => ({
            ...prevState,
            companyDetails: {
                ...prevState.companyDetails,
                currency: {
                    code:  currencyList.filter((type: ICurrencyDetailsState) => type.name === event.target.value)[0].code,
                    currencyId: currencyList.filter((type: ICurrencyDetailsState) => type.name === event.target.value)[0].currencyId,
                    name: currencyList.filter((type: ICurrencyDetailsState) => type.name === event.target.value)[0].name,
                }
            }
        }))
    };

    const onSelectCompanyTypeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        companyTypeList.filter((type: ICompanyTypeState) => type.name === event.target.value).length > 0 &&
        setCompanyDetailsState(prevState => ({
            ...prevState,
            companyDetails: {
                ...prevState.companyDetails,
                company: {
                    name: companyTypeList.filter((type: ICompanyTypeState) => type.name === event.target.value)[0].name,
                    companyTypeId: companyTypeList.filter((type: ICompanyTypeState) => type.name === event.target.value)[0].companyTypeId,
                }
            }

        }));
    };

    const onChangeCompanyName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setCompanyDetailsState(prevState => ({
            ...prevState,
            companyDetails: {
                ...prevState.companyDetails,
                name: event.target.value,
            }
        }));
    };

    const onClickContinueToMasterDetailsHandler = () => {
        pushToNextRoute(history, '/master-details');
        dispatch(updateCompanyDetails(companyDetailsState));
    };

    return (
        <StyledCompanyDetails className="height-controller-wrapper">
            <Header title="Company details"/>
            <form>
                <GeneralTextField id="company-name"
                                  onChange={onChangeCompanyName}
                                  value={companyDetailsState ? companyDetailsState.companyDetails.name : ''}
                                  variant="filled"
                                  placeholder="Company name"
                />
                <FormControl>
                    <CustomSelect id="country"
                                  onChangeHandler={onSelectCountryHandler}
                                  value={companyDetailsState ? companyDetailsState.companyDetails.country.name : ''}
                                  defaultValue="Country"
                                  optionsArray={extractNamesArr(countryList)}/>
                </FormControl>
                <FormControl>
                    <CustomSelect id="currency"
                                  onChangeHandler={onSelectCurrencyHandler}
                                  value={companyDetailsState ? companyDetailsState.companyDetails.currency.name : ''}
                                  defaultValue="Currency"
                                  optionsArray={extractNamesArr(currencyList)}/>
                </FormControl>
                <FormControl className="company-type">
                    <CustomSelect id="company-type"
                                  onChangeHandler={onSelectCompanyTypeHandler}
                                  value={companyDetailsState ? companyDetailsState.companyDetails.company.name : ''}
                                  defaultValue="Company or solo"
                                  optionsArray={extractNamesArr(companyTypeList)}/>
                </FormControl>
            </form>
            <div className="actions-wrapper">
                <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
                <PrimaryButton className="continue-button"
                               disabled={!!isButtonDisabled}
                               onClick={onClickContinueToMasterDetailsHandler}>Continue</PrimaryButton>
            </div>
        </StyledCompanyDetails>
    );
};
