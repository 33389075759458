import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";


const StyledLoading = styled.div `
  text-align: center;
  background-color: ${PaymeTheme.colorWhite};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  img {
    width: 40px;
    height: auto;
    border: none;
    border-radius:50%;
    animation:spin 1100ms ease-in-out infinite;
    -webkit-animation:spin 1100ms ease-in-out infinite;
  }
  
  @keyframes spin {
  to {
  -webkit-transform: scale(2) rotate(180deg)
  }
  }
  
  @-webkit-keyframes spin {
  to {
  -webkit-transform: scale(2) rotate(180deg)
  }
  }
  ${media.desktopMedium} {
  margin: 0;
  }
  `;

export { StyledLoading } ;
