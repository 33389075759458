import { all } from "redux-saga/effects";
import productsListSagas from "./pages/SelectProduct/sagas";
import countryListSagas from "./pages/CompanyDetails/sagas";
import companyRegistrationSagas from "./pages/TermsAndConditions/sagas";

export default function* indexSaga() {
  yield all([
      ...productsListSagas,
      ...countryListSagas,
      ...companyRegistrationSagas
  ]);
}
