import {applyMiddleware, compose, createStore, Middleware, Store} from 'redux';
import createRootReducer from "./rootReducer";
import {createBrowserHistory, History} from 'history'
import {routerMiddleware} from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import indexSaga from "../rootSagas";


export const history: History = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// creates the store
const configureStore = (initialState = {}) => {

    const _getDevTools = () =>
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Prevent recomputing reducers for `replaceReducer`
            shouldHotReload: false,
        });

    const _enableHotLoader = (store: Store<any>, history: History): void => {
        const { hot } = module as any;
        if (hot) {
            hot.accept('store/rootReducer', () => {
                const nextRootReducer = createRootReducer(history);
                store.replaceReducer(nextRootReducer);
            });
        }
    };

    const enhancers = [];

    if (process.env.NODE_ENV === 'development') {
        const devToolsExtension = _getDevTools();
        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const _getMiddleware = (history: History): Middleware[] => [
        routerMiddleware(history),
        sagaMiddleware,
        // thunk,
        // analyticsMiddleware,
        // createStateSyncMiddleware(stateSyncConfig),
    ];

    const composedEnhancers = compose(
        applyMiddleware(..._getMiddleware(history)),
        //applyMiddleware(sagaMiddleware),
        ...enhancers,
    );

    const store = createStore(createRootReducer(history), initialState, composedEnhancers);

    sagaMiddleware.run(indexSaga);

    // Enable reducer hot reloading after store was created only in development
    if (process.env.NODE_ENV === 'development') {
        _enableHotLoader(store, history);
    }

    return {store, history}
};


export default configureStore;
