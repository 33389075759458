import React, { FC } from "react";
import { StyledHeader } from "./styles";

interface Props {
  title: string;
}

const Header: FC<Props> = ({ title }) => {
  return (
    <StyledHeader>
      {title}
    </StyledHeader>
  );
};

export default Header;
