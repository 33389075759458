import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {History} from "history";
import {getProductsListReducer} from "../pages/SelectProduct/reducer";
import {getCompanyListReducer, getCountryListReducer, getCurrencyListReducer} from "../pages/CompanyDetails/reducer";
import {updateCompanyRegistrationDetailsReducer} from "../generalSagas/reducer";
import {getCompanyRegistrationReducer} from "../pages/TermsAndConditions/reducer";


const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    productsList: getProductsListReducer,
    countryList: getCountryListReducer,
    currencyList: getCurrencyListReducer,
    companyList: getCompanyListReducer,
    companyRegistrationDetails: updateCompanyRegistrationDetailsReducer,
    companyRegistrationRequest: getCompanyRegistrationReducer
  });
};

export default createRootReducer;
