export const TERMS_DATA = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae placerat elit. Nulla viverra dignissim turpis. Nunc efficitur arcu ut consectetur tempor. Suspendisse potenti. Integer commodo, libero quis fringilla suscipit, odio mi pulvinar est, eu lobortis ipsum dolor a leo. Nunc mollis lacus at ligula porttitor viverra nec in sapien. Duis finibus lobortis eros, volutpat congue turpis tempor interdum. Cras congue ex eget leo commodo iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur sit amet sollicitudin nulla. Proin lectus magna, mollis sed consequat quis, dapibus in urna. Etiam mollis metus urna, at gravida ante aliquam eget. Quisque egestas malesuada finibus. Mauris sed maximus dolor. Nam risus turpis, vehicula id justo consectetur, luctus malesuada felis. Sed blandit mattis erat nec facilisis.
Nullam molestie, tortor nec pretium congue, velit nisl convallis augue, a dictum urna dui finibus odio. Aenean sit amet tempor turpis. Donec imperdiet ex vitae accumsan faucibus. Nullam at magna nec lectus venenatis aliquam. Maecenas eget sapien quis ante commodo posuere. Integer imperdiet faucibus elit ut porta. Nulla nibh magna, feugiat eget enim sit amet, malesuada condimentum ante. Integer porttitor lacus convallis, scelerisque quam at, sagittis leo. Mauris a risus et mauris feugiat condimentum vitae sed sapien. In hac habitasse platea dictumst. Pellentesque in orci lectus. In tincidunt ante in dui ullamcorper, nec feugiat nibh dapibus. Praesent vel blandit turpis. Vivamus placerat erat quis mauris rutrum blandit. Sed elementum nulla ut ultrices viverra. Vivamus mollis at libero ut sodales.
Donec lacinia arcu venenatis, sollicitudin enim non, convallis tortor. Ut vulputate dui ac nulla mattis, in pellentesque ipsum aliquet. Nunc eu est in diam ultricies ullamcorper. Nam et convallis diam. Aenean vitae suscipit nisi. Praesent mollis eu eros sit amet gravida. Nulla facilisi. In sit amet velit nulla. In dictum leo et nulla condimentum pellentesque. Vestibulum at lectus vel ipsum pellentesque mollis et eget lacus. Aliquam mollis semper dui non blandit. Quisque eget fermentum purus. Ut consectetur accumsan tellus vel porttitor.
Phasellus ut finibus libero. Etiam tempor vulputate porta. Nulla nec massa lectus. Vivamus vitae tristique orci. Pellentesque sed odio eu tortor ultricies finibus. Pellentesque ac pharetra nisl. Nullam lobortis condimentum egestas.
Nulla urna lacus, convallis non ligula eget, porttitor sodales felis. Maecenas vulputate tortor sit amet augue laoreet blandit. Vivamus sed arcu facilisis, tincidunt nunc sit amet, sollicitudin libero. Sed malesuada, ante ut malesuada bibendum, dolor urna pulvinar ante, ac congue ipsum mi quis dolor. Etiam ut turpis non eros tristique hendrerit. Etiam et erat erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer ac turpis vel purus tempus scelerisque ac nec enim. Pellentesque interdum aliquam quam. Donec in bibendum risus, vitae luctus tortor.`;

export const TERMS_ACCEPT = `I confirm that I have read and understood the terms and conditions`;

export const GET_COMPANY_REGISTRATION_ATTEMPT = 'GET_COMPANY_REGISTRATION_ATTEMPT';
export const GET_COMPANY_REGISTRATION_SUCCESS = 'GET_COMPANY_REGISTRATION_SUCCESS';
export const GET_COMPANY_REGISTRATION_FAILURE = 'GET_COMPANY_REGISTRATION_FAILURE';