import {
  GET_COMPANY_LIST_FAILURE,
  GET_COMPANY_LIST_REQUEST, GET_COMPANY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS, GET_CURRENCY_LIST_FAILURE,
  GET_CURRENCY_LIST_REQUEST, GET_CURRENCY_LIST_SUCCESS
} from "./constants";

// country actions

export const countryListAttempt = () => ({
  type: GET_COUNTRY_LIST_REQUEST,
});

export const countryListSuccess = (data: any) => ({
  type: GET_COUNTRY_LIST_SUCCESS,
  payload: { ...data},
});

export const countryListFailure = (error: any) => ({
  type: GET_COUNTRY_LIST_FAILURE,
  payload: error
});

// currency actions

export const currencyListAttempt = () => ({
  type: GET_CURRENCY_LIST_REQUEST,
});

export const currencyListSuccess = (data: any) => ({
  type: GET_CURRENCY_LIST_SUCCESS,
  payload: { ...data},
});

export const currencyListFailure = (error: any) => ({
  type: GET_CURRENCY_LIST_FAILURE,
  payload: error
});

// company options actions

export const companyListAttempt = () => ({
  type: GET_COMPANY_LIST_REQUEST,
});

export const companyListSuccess = (data: any) => ({
  type: GET_COMPANY_LIST_SUCCESS,
  payload: { ...data},
});

export const companyListFailure = (error: any) => ({
  type: GET_COMPANY_LIST_FAILURE,
  payload: error
});
