import {ICompanyRegistrationDetailsState} from "../../@types";
import {
  GET_COMPANY_REGISTRATION_ATTEMPT,
  GET_COMPANY_REGISTRATION_FAILURE,
  GET_COMPANY_REGISTRATION_SUCCESS
} from "./constants";


const companyRegistrationInitialState: ICompanyRegistrationDetailsState = {
  data: null,
  loading: false,
  error: null
};

export const getCompanyRegistrationReducer = (state = companyRegistrationInitialState, action: any) => {
  switch (action.type) {
    case GET_COMPANY_REGISTRATION_ATTEMPT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_COMPANY_REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_COMPANY_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
