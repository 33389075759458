import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";


const StyledProductCard = styled.div `
  position: relative;
  .product-name {
    color: ${paymeTheme.errorColor};
    padding: ${paymeTheme.paddingRemSm};
    font-size: ${paymeTheme.fontRemSizeNormal};
  }
  
  .product-description {
    border: 1px solid ${paymeTheme.borderGrey};
    border-radius: 10px;
    color: ${props => props.theme.checked ? paymeTheme.lightBlack : paymeTheme.colorWhite}};
    padding: ${paymeTheme.paddingRemXs};
  }

  .product-change-background {
    background-color: ${props => props.theme.checked ? props.theme.green : paymeTheme.headerColor}};
  }
  
  .MuiCheckbox-root {
    position: absolute;
    right: 0;
  }
`;

export { StyledProductCard } ;
