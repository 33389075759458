const paymeTheme = {
    //Paddings
    paddingRem: '1.25rem',
    paddingRemXLg: '2.5rem',
    paddingRemLg: '1.875rem',
    paddingRemMd: '0.9rem',
    paddingRemSm: '0.6rem',
    paddingRemXs: '0.5rem',
    //Brand Colors
    warningColor: '#FF8A02',
    colorBlack: '#000000',
    colorWhite: '#fff',
    errorColor: '#E50000',
    successColor: '#6FBC14',
    greenStatusColor: '#178900',
    lightGreen: '#eaffe6',
    colorDarkGray: 'grey',
    footerBackground: '#0B2D5A',
    floteColor: '#0090DF',
    headerColor: '#0B2D5A',
    customColor: '#7F7F7F',
    lightGrey: '#D3D3D3',
    borderGrey: '#D5D5D5',
    clientPurple: '#e200e2',
    colorYellow: 'yellow',
    lightBlack: '#232323',
    //vh height
    heightLg: '20vh',
    heightMd: '13vh',
    heightSm: '7vh',
    heightXs: '5vh',
    //vw width
    widthLg: '75vw',
    widthMd: '50vw',
    widthSm: '20vw',
    widthXs: '10vw',
    //Font
    fontLight: '300',
    fontRegular: '400',
    fontMedium: '500',
    fontRegularItalic: 'italic',
    fontSemiBold: '600',
    fontBold: '700',
    fontExtraBold: '800',
    fontRemSizeXLg: '1.5rem',
    fontRemSizeLg: '1.125rem',
    fontRemSizeBase: '0.875rem',
    fontRemSizeSmall: '0.813rem',
    fontRemSizeXs: '0.625rem',
    fontRemSizeNormal: '1rem',
    fontRemSizeH1: '3.4rem',
    fontRemSizeH2: '2.25rem',
    fontRemSizeH3: '2rem',
    fontRemSizeH4: '1.75rem',
    fontRemSizeH5: '1.375rem',
    fontRemSizeH6: '1.25rem',
    //Line height:
    lineHeightRemNormal: '1rem',
    //Input
    inputHeight: '39px',
    //Desktop Medium 
    sideBarWidth: '4rem',
    listItemPadding: '5.25rem'
};

export default paymeTheme;
