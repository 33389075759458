import {IProductsListState} from "./model";
import {
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, UPDATE_PRODUCTS_LIST
} from "./constants";


const productsListInitialState: IProductsListState = {
  data: [],
  loading: false,
  error: null
};

export const getProductsListReducer = (state = productsListInitialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PRODUCTS_SUCCESS:
    case UPDATE_PRODUCTS_LIST:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
