import React, { FC } from "react";
import { StyledFooter } from "./styles";
import PoweredByImg from "../../assets/images/poweredBy.png";
import { IKeyboardStatePosition } from "../../@types";
import LogoSvgComponent from "./FooterSvgLogo";

interface Props {
  position: IKeyboardStatePosition;
}

const Footer: FC<Props> = ({position}) => {
  return (
    <StyledFooter theme={position}>
      <LogoSvgComponent/>
    </StyledFooter>
  );
};

export default Footer;
