import {UPDATE_COMPANY_DETAILS} from "./constants";
import {ICompanyRegistrationDetailsState} from "../@types";


const companyRegistrationDetailsInitialState: ICompanyRegistrationDetailsState = {
  data: {
    productIds: [],
    companyDetails: {
      name: '',
      country: {
        countryId: -1,
        name: '',
        code: '',
      },
      currency: {
        currencyId: -1,
        name: '',
        code: '',
      },
      company: {
        companyTypeId: -1,
        name: '',
      }

    },
    masterDetails: {
      firstName: '',
      surName: '',
      email: '',
    }
  }
};

export const updateCompanyRegistrationDetailsReducer = (state = companyRegistrationDetailsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_COMPANY_DETAILS:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

