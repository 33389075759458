import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";


const StyledSelectProduct = styled.div `
  color: ${paymeTheme.colorBlack};
  padding: ${paymeTheme.paddingRemSm};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .user-helper-description {
    text-align: center;
    font-weight: ${paymeTheme.fontBold};
    max-width: ${paymeTheme.widthLg};
    color: ${paymeTheme.colorWhite};
  }
    
  ${media.tablet} {
    max-width: ${paymeTheme.widthLg};
    margin: auto;
    .user-helper-description {
         padding-top: ${paymeTheme.paddingRemXLg};
    }
  }
  
  ${media.desktop} {
    max-width: ${paymeTheme.widthMd};
  }
`;

export { StyledSelectProduct } ;
