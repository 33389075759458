import withStyles from "@material-ui/core/styles/withStyles";
import { Button, Link } from "@material-ui/core";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, Props} from 'react';
import paymeTheme from "../../assets/styling/payme-theme";


export const PrimaryButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: paymeTheme.floteColor,
    color: paymeTheme.colorWhite,
    fontWeight: 300,
    margin: '5px auto 0',
    height: '35px',
    width: paymeTheme.widthMd,
    maxWidth: '140px',
    minWidth: '120px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '9px',
    lineHeight: paymeTheme.lineHeightRemNormal,

    '&:hover': {
      backgroundColor: paymeTheme.headerColor,
      color: paymeTheme.floteColor,
    },
  },
})(Button);


export const OutlinedButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: paymeTheme.colorWhite,
    color: paymeTheme.floteColor,
    border: `1px solid ${paymeTheme.floteColor}`,
    fontWeight: 300,
    margin: '5px auto 0',
    height: '35px',
    width: paymeTheme.widthMd,
    maxWidth: '140px',
    minWidth: '120px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '9px',
    lineHeight: paymeTheme.lineHeightRemNormal,

    '&:hover': {
      backgroundColor: paymeTheme.colorWhite,
      fontWeight: 700,
      borderColor: paymeTheme.headerColor,
    },
  },
})(Button);

interface ButtonProps {
  buttonContent: string;
  linkHandler: () => void;
}

export const ButtonLink: FC<ButtonProps> = ({buttonContent, linkHandler}) => {
  return (
    <Link
      component="button"
      variant="body2"
      onClick={linkHandler}
    >
      {buttonContent}
    </Link>
  );
};

