export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_REQUEST = "GET_COUNTRY_LIST_REQUEST";
export const GET_COUNTRY_LIST_FAILURE = "GET_COUNTRY_LIST_FAILURE";

export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";
export const GET_CURRENCY_LIST_REQUEST = "GET_CURRENCY_LIST_REQUEST";
export const GET_CURRENCY_LIST_FAILURE = "GET_CURRENCY_LIST_FAILURE";

export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_REQUEST = "GET_COMPANY_LIST_REQUEST";
export const GET_COMPANY_LIST_FAILURE = "GET_COMPANY_LIST_FAILURE";
