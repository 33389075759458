import { ICompanyRegistrationDetails } from "../../@types";
import {
    GET_COMPANY_REGISTRATION_ATTEMPT,
    GET_COMPANY_REGISTRATION_FAILURE,
    GET_COMPANY_REGISTRATION_SUCCESS
} from "./constants";

export const getCompanyRegistrationAttempt = (data: ICompanyRegistrationDetails | null) => ({
    type: GET_COMPANY_REGISTRATION_ATTEMPT,
    payload: {...data}
});

export const getCompanyRegistrationSuccess = (data: any) => ({
    type: GET_COMPANY_REGISTRATION_SUCCESS,
    payload: {...data}
});

export const getCompanyRegistrationFailure = (error: any) => ({
    type: GET_COMPANY_REGISTRATION_FAILURE,
    payload: error
});

