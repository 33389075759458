import { IArgsApi } from "./@types";
import axios from 'axios'

export const asyncRequest = async ({ config, endpoint }: IArgsApi) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/${endpoint}`;
  const reqConfig = {
    url,
    headers: {
      "Content-Type": "application/json",
      "Authorization":  localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    },
    mode: "no-cors",
    ...config
  };


  const response = await axios(reqConfig)
  console.log('response axios', response);
  if (response) {
    try {
      // if(config.method === 'DELETE') return response
      return response.data
    } catch (err) {
      throw err.message;
    }
  } else {
    throw Error(response);
  }
};
