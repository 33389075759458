import styled from 'styled-components';
import paymeTheme from "../../assets/styling/payme-theme";
import media from "../../assets/styling/payme-media-queries";


const StyledCompanyDetails = styled.div `
  .company-type {
    margin-top: ${paymeTheme.paddingRemLg};
  }

  ${media.tablet} {
    max-width: ${paymeTheme.widthLg};
    margin: auto;
  }
  
  ${media.desktop} {
    max-width: ${paymeTheme.widthMd};
  }
`;

export { StyledCompanyDetails } ;
