import {
    ICompanyTypeState,
    ICountryDetailsState, ICurrencyDetailsState,
} from "../pages/CompanyDetails/model";

export const extractNamesArr = (arr: ICountryDetailsState[] | ICurrencyDetailsState[] | ICompanyTypeState[]) => {
    const optionsNamesArray:any[] = [];
    arr && arr.length && arr.forEach((item: any) => {
        optionsNamesArray.push(item.name);
    });

    return optionsNamesArray;
};