import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {StyledSelectProduct} from "./styles";
import {SELECT_PRODUCT_HELPER_DESCRIPTION} from "./constants";
import {productsDetailsAttempt, updateProductsList} from "./actions";
import {getProductsListData} from "./selectors";
import Header from "../../components/Header";
import {PrimaryButton} from "../../components/Buttons";
import {getCompanyDetailsData} from "../../generalSagas/selectors";
import {updateCompanyDetails} from "../../generalSagas/actions";
import {ProductCard} from "./ProductCard";
import {pushToNextRoute} from "../../utils/pushToNextRoute";

export const SelectProduct: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const params = new URLSearchParams(window.location.search.substring(1));
    const productsParam = params.get('product');
    const productsArrFromUrl = productsParam && productsParam.split(',');

    const initialProductsList = useSelector(getProductsListData);
    const companyRegistrationDetails = useSelector(getCompanyDetailsData);
    const [productsListState, setProductsListState] = useState(initialProductsList);

    useEffect(() => {
        initialProductsList.length == 0 && dispatch(productsDetailsAttempt());
    }, []);

    const extendedProductsList = initialProductsList && initialProductsList.length > 0 &&
        initialProductsList.map(item => {
            if (item.hasOwnProperty('checked')) {
                return item;
            }
            if (productsArrFromUrl && productsArrFromUrl.length > 0 && !item.hasOwnProperty('checked')) {
                if (productsArrFromUrl.indexOf(item.code) !== -1) {
                    return {
                        ...item,
                        label: item.name,
                        value: item.code,
                        checked: true,
                        checkBoxName: item.name,
                    }
                } else {
                    return {
                        ...item,
                        label: item.name,
                        value: item.code,
                        checked: false,
                        checkBoxName: item.name,
                    }
                }
            }
            if (!item.hasOwnProperty('checked') && !productsArrFromUrl) {
                return {
                    ...item,
                    label: item.name,
                    value: item.code,
                    checked: false,
                    checkBoxName: item.name,
                }
            }
            return item;
    });

    useEffect(() => {
        extendedProductsList && setProductsListState(extendedProductsList);
    }, [initialProductsList]);

    const handleChangeProductsCheckboxes = (e:React.ChangeEvent<HTMLInputElement>) => {
        const updatedProductsList = productsListState
            .map(item => {
                if (item.checkBoxName !== e.target.name) {
                    return item;
                }
                return {
                    ...item,
                    checked: e.target.checked
                }
            });
        setProductsListState(updatedProductsList);
    };

    const handleContinueClick = () => {
        const productIds: number[] | undefined = [];
        pushToNextRoute(history, '/company-details');
        productsListState.map(item => {
            item.checked && productIds.push(item.productId);
        });
        dispatch(updateProductsList(productsListState));
        dispatch(updateCompanyDetails({
            ...companyRegistrationDetails,
            productIds: [...productIds]}
            ));
    };

    return (
        <StyledSelectProduct className="height-controller-wrapper">
            <Header title="Select product"/>
            <div className="user-helper-description">{SELECT_PRODUCT_HELPER_DESCRIPTION}</div>
            <div className="products-wrapper">
            {productsListState && productsListState.length > 0 &&
            productsListState.map((product: any, index: number) => {
                return <ProductCard product={product} key={`product-${index}`} onChangeHandler={handleChangeProductsCheckboxes}/>
            })
            }
            </div>
            <div className="actions-wrapper">
                <PrimaryButton onClick={handleContinueClick}
                               disabled={!productsListState.some(item => item.checked === true)}
                >
                    Continue
                </PrimaryButton>
            </div>
        </StyledSelectProduct>
    );
};
