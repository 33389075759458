import { put, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANY_LIST_FAILURE, GET_COMPANY_LIST_REQUEST, GET_COMPANY_LIST_SUCCESS, GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS, GET_CURRENCY_LIST_FAILURE, GET_CURRENCY_LIST_REQUEST, GET_CURRENCY_LIST_SUCCESS,
} from "./constants";
import {asyncRequest} from "../../api";
import {IAction, ICompanyListState, ICountryListState, ICurrencyListState} from "./model";


export function* fetchCountryListSaga(action: IAction) {
  try {
    const getCountryList: ICountryListState = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/shared/country',
    });


    yield put({ type: GET_COUNTRY_LIST_SUCCESS, payload: getCountryList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_COMPANY_LIST_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export function* fetchCurrencyListSaga(action: IAction) {
  try {
    const getCurrencyList: ICurrencyListState = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/shared/currency',
    });


    yield put({ type: GET_CURRENCY_LIST_SUCCESS, payload: getCurrencyList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_CURRENCY_LIST_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}


export function* fetchCompanyListSaga(action: IAction) {
  try {
    const getCompanyList: ICompanyListState = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/company/type',
    });


    yield put({ type: GET_COMPANY_LIST_SUCCESS, payload: getCompanyList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_COMPANY_LIST_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}

export default  [
  takeLatest(GET_COUNTRY_LIST_REQUEST, fetchCountryListSaga),
  takeLatest(GET_CURRENCY_LIST_REQUEST, fetchCurrencyListSaga),
  takeLatest(GET_COMPANY_LIST_REQUEST, fetchCompanyListSaga),
]
