import React, {FC} from 'react';
import {StyledProductCard} from "./styles";
import {IProductDetailsState} from "../model";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import paymeTheme from '../../../assets/styling/payme-theme';

interface Props {
product: IProductDetailsState,
onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
}


export const ProductCard: FC<Props> = ({product, onChangeHandler}) => {
    const backgroundColor = {
        green: paymeTheme.lightGreen,
        checked: product.checked
    };

    return (
        <StyledProductCard theme={backgroundColor}>
            <div className="product-name">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={product.checked}
                            onChange={onChangeHandler}
                            color="primary"
                            name= {product.checkBoxName}/>}
                    label={product.label}
                />
            </div>
            <div className="product-description product-change-background">{product.description}</div>
        </StyledProductCard>
    );
};
