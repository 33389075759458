import React, {FC} from 'react';
import {useHistory} from "react-router-dom";
import { useSelector} from "react-redux";
import Header from "../../components/Header";
import {WELCOME_DETAILS, EMAIL_HELPER_DESCRIPTION} from "./constants";
import { StyledCardDetails } from "../../components/containers/Main/styles";
import {companyRegistrationRequestError} from "../TermsAndConditions/selectors";
import {ImportantNotification, NotificationError} from "../../components/NotificationMessages";
import { OutlinedButton } from '../../components/Buttons';

export const WelcomeDetails = () => {
    const history = useHistory();

    const companyRegistrationError = useSelector(companyRegistrationRequestError);

    return (
        <StyledCardDetails className="height-controller-wrapper welcome-details" theme={{isWelcomeDetails: true}}>
            <Header title="Welcome details"/>
            {companyRegistrationError && (
                <div>
                    <NotificationError message={companyRegistrationError} messageClass="error"/>
                    <div className="actions-wrapper">
                        <OutlinedButton onClick={() => {history.goBack()}}>Back</OutlinedButton>
                    </div>
                </div>
            )}
            {companyRegistrationError === null && (
                <div className="successful-registration-wrapper">
                    <div className="card-details">
                        <p>{WELCOME_DETAILS}</p>
                        <p>{EMAIL_HELPER_DESCRIPTION}</p>
                    </div>
                </div>
            )}
        </StyledCardDetails>
    );
};

