import React, { FC } from "react";
import {StyledErrorMessage, StyledImportantNotification} from "./styles";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


interface Props {
  message: string;
  messageClass?: string;
  bold?: string;
}

export const NotificationError: FC<Props> = ({ messageClass, message}) => {

  return (
    <StyledErrorMessage>
      <div className={messageClass}>
        <ErrorOutlineIcon/>
        <div className="message-content">{message}</div>
      </div>
    </StyledErrorMessage>
  );
};

export const ImportantNotification: FC<Props> = ({message, bold}) => {
  const split = message.split(`${bold}`);

  return (
    <StyledImportantNotification>
      {bold ? (<div>
        <p>{split[0]}</p>
        <p className="bold">{bold}</p>
        <p>{split[1]}</p>
      </div>) : <p>{message}</p>}
    </StyledImportantNotification>
  )
};
