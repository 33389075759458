import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import paymeTheme from "../../assets/styling/payme-theme";


export const StyledErrorMessage = styled.div ` 
  .error {
    background-color: ${paymeTheme.headerColor};
    color: ${paymeTheme.errorColor};
    padding: ${paymeTheme.paddingRemMd};
    display: flex;
    align-items: center;
    z-index: 3;
    position: fixed;
    width: fit-content;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 10vh;
    margin: auto;
  }
  .message-content {
    padding-left: ${paymeTheme.paddingRemSm};
    font-size: ${paymeTheme.fontRemSizeH6};
  }
  
  ${media.desktopMedium} {
    margin: 0;
    .message-content {
      font-size: ${paymeTheme.fontRemSizeH4}!important;
    }

    .error {
      svg {
        height: 2rem;
      }
    }
  }

  ${media.desktop} {
    padding-top: 2.5rem;
    .message-content {
      font-size: ${paymeTheme.fontRemSizeH5};
    }
  }
`;

export const StyledImportantNotification = styled.div ` 
  background-color: yellow;
  font-weight: ${paymeTheme.fontRegular};
  font-size: ${paymeTheme.fontRemSizeH5};
  padding: ${paymeTheme.paddingRemMd};
  text-align: center;
  top: 0;
  right: 0;
  left: 0;

  p {
    margin: 0;
    color: ${paymeTheme.colorBlack};
    display: inline;
  }

  .bold {
    font-weight: ${paymeTheme.fontBold};
  }
  
  ${media.desktopMedium} {
    margin: 0;
  }

  ${media.desktop} {
    padding-top: 2.5rem;
  }
`;

