import styled from 'styled-components';
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";

const StyledFooter = styled.div `
  text-align: center;
  background-color: ${PaymeTheme.footerBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 3.75rem;
  width: 100%;
  bottom: 0;
  position: ${props => props.theme.keyboard === true ? 'absolute' : 'fixed'};
  
  svg {
    width: 100%;
    height: auto;
  }
  
  
  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledFooter } ;
