// Breakpoints for responsive design in px
export const sizes = {
    desktopLarge: 1200, // Extra large devices (large desktops, 1200px and up)
    desktopMedium: 1170, // large devices (medium desktops, 1170px and up)
    desktop: 992, // Large devices (desktops, 992px and up)
    tablet: 768, // Medium devices (tablets, 768px and up)
    phone: 480, // Small devices (landscape phones, 480px and up) TODO: We should consider updating this to 576px, as in Bootstrap 4
};

export const minWidthMediaQuery = (breakpoint: number): string => `@media (min-width: ${breakpoint}px)`;

// Sizes as media queries (min-width)
export const media = {
    desktopLarge: minWidthMediaQuery(sizes.desktopLarge),
    desktopMedium: minWidthMediaQuery(sizes.desktopMedium),
    desktop: minWidthMediaQuery(sizes.desktop),
    tablet: minWidthMediaQuery(sizes.tablet),
    phone: minWidthMediaQuery(sizes.phone),
};

/* TODO: Remove usages of mediaMaxWidth - There are very few of them, and they are all mediaMaxWidth.phone.
    In a more mobile-first design, mediaMaxWidth.sm should just be the default style.
    Alternative styling for larger screens can be applied with media.tablet.
    We should be thinking mobile-first since the majority of our users use the site on mobile web. */
export const maxWidthMediaQuery = (breakpoint: number): string => `@media (max-width: ${breakpoint - 0.02}px)`;

// Sizes as media queries (max-width)
const mediaMaxWidth = {
    desktopLarge: maxWidthMediaQuery(sizes.desktopLarge),
    desktop: maxWidthMediaQuery(sizes.desktop),
    tablet: maxWidthMediaQuery(sizes.tablet),
    phone: maxWidthMediaQuery(sizes.phone),
};

export { mediaMaxWidth };
export default media;
