import styled from 'styled-components';
import paymeTheme from '../../assets/styling/payme-theme';
import media from "../../assets/styling/payme-media-queries";


const StyledTermsAndConditions = styled.div `
    .terms-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 15.625rem);
        max-width: 31.25rem;
        margin: auto;
        align-items: center;

        ${media.desktopMedium} {
            min-height: auto;
            height: auto;
        }
    }

    .terms-accept {
        display: flex;
        justify-content: center;
        padding-right: ${paymeTheme.paddingRemMd};
        padding-left: ${paymeTheme.paddingRemMd};
        color: ${paymeTheme.colorWhite};
        width: 65vw;
        max-width: 600px;

        p {
            text-align: center;
            font-weight: ${paymeTheme.fontSemiBold};
        }
    }

    .terms-data {
        padding: ${paymeTheme.paddingRemSm};
        width: 70vw;
        max-width: 600px;
        height: auto;
        background-color: transparent;
        color: ${paymeTheme.colorWhite};

        p {
            padding: ${paymeTheme.paddingRemSm};
            margin: ${paymeTheme.paddingRemSm};
        }
    }
`;

export { StyledTermsAndConditions } ;
