import { put, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANY_REGISTRATION_FAILURE,
  GET_COMPANY_REGISTRATION_SUCCESS,
  GET_COMPANY_REGISTRATION_ATTEMPT
} from "./constants";
import {asyncRequest} from "../../api";
import {IAction} from "../SelectProduct/model";
import {ICompanyRegistrationDetailsState} from "../../@types";
import { push } from "connected-react-router";

export function* getCompanyRegistration(action: IAction) {
  try {
    const getCompanyRegistrationDetails: ICompanyRegistrationDetailsState = yield asyncRequest({
      config: {
        method: "POST",
        data: {
            companyName: action.payload.companyDetails.name,
            firstName: action.payload.masterDetails.firstName,
            lastName: action.payload.masterDetails.surName,
            email: action.payload.masterDetails.email,
            countryId: action.payload.companyDetails.country.countryId,
            currencyId: action.payload.companyDetails.currency.currencyId,
            companyTypeId: action.payload.companyDetails.company.companyTypeId,
            products: action.payload.productIds
        }
      },
      endpoint: 'api/company',
    });

    yield put({ type: GET_COMPANY_REGISTRATION_SUCCESS, payload: getCompanyRegistrationDetails });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_COMPANY_REGISTRATION_FAILURE, payload: error && error.response && error.response.data && error.response.data.title
      ? error.response.data.title : 'Unknown error' });
  }

  yield put(push('/welcome-details'));
}


export default  [
  takeLatest(GET_COMPANY_REGISTRATION_ATTEMPT, getCompanyRegistration),
]
