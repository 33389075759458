import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "@material-ui/core";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {StyledTermsAndConditions} from  "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {TERMS_ACCEPT, TERMS_DATA} from "./constants";
import { getCompanyDetailsData } from '../../generalSagas/selectors';
import {getCompanyRegistrationAttempt} from "../TermsAndConditions/actions";
import { companyRegistrationRequestLoading } from './selectors';
import LoadingComponent from '../../components/Loading';

export const TermsAndConditions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [accept, setAccept] = useState(false);
    
    const companyRegistrationDetails = useSelector(getCompanyDetailsData);
    const companyRegistrationLoading = useSelector(companyRegistrationRequestLoading);

    const acceptTerms = () => {
        setAccept(!accept);
    }

    return (
        <StyledTermsAndConditions className="height-controller-wrapper">
            <Header title="Terms and conditions"/>
            {companyRegistrationLoading && <LoadingComponent/>}
            {!companyRegistrationLoading && <div className="terms-form">
                <div className="terms-data">
                    <p>{TERMS_DATA}</p>
                </div>
                <div className="terms-accept">
                    <p>{TERMS_ACCEPT}</p>
                    <Checkbox
                        onChange={acceptTerms}
                        icon={<CircleUnchecked />}
                        checkedIcon={<RadioButtonCheckedIcon />}
                    />
                </div>
            </div>}
            {!companyRegistrationLoading && <div className="actions-wrapper">
                <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
                <PrimaryButton className="continue-button"
                                onClick={() => dispatch(getCompanyRegistrationAttempt(companyRegistrationDetails))}
                                disabled={!accept}>
                    Confirm
                </PrimaryButton>
            </div>}
        </StyledTermsAndConditions>
    );
};
