import React, { FC } from "react";
import { StyledLoading } from "./styles";
import Loading from "../../assets/images/Loading.svg";


const LoadingComponent: FC = () => {
  return (
    <StyledLoading>
        <img src={Loading} alt="loading..."/>
    </StyledLoading>
  );
};

export default LoadingComponent;
