import React, {FC} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {FINANCIAL_DETAILS_CONTENT} from "./constants";
import { StyledCardDetails } from "../../components/containers/Main/styles";
import { pushToNextRoute } from '../../utils/pushToNextRoute';

interface Props {

}


export const FinancialDetails: FC<Props> = ({}) => {
    const history = useHistory();

    return (
        <StyledCardDetails className="height-controller-wrapper" theme={{isWelcomeDetails: false}}>
            <Header title="Financial details"/>
            {/* <div className="card-details">
                <p>{FINANCIAL_DETAILS_CONTENT}</p>
            </div> */}
            <div className="actions-wrapper">
                <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
                <PrimaryButton className="continue-button"
                                onClick={() => pushToNextRoute(history, '/terms-and-conditions')}>
                    Continue
                </PrimaryButton>
            </div>

        </StyledCardDetails>
    );
};
