import React, {FC, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {StyledMasterDetails} from "./styles";
import Header from "../../components/Header";
import {OutlinedButton, PrimaryButton} from "../../components/Buttons";
import {pushToNextRoute} from "../../utils/pushToNextRoute";
import {GeneralTextField} from "../../components/Inputs";
import {getCompanyDetailsData} from "../../generalSagas/selectors";
import {updateCompanyDetails} from "../../generalSagas/actions";
import {validateEmail} from "../../utils/validateEmail";


export const MasterDetails: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const companyRegistrationDetails = useSelector(getCompanyDetailsData);

    const [companyDetailsState, setCompanyDetailsState] = useState(companyRegistrationDetails);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailValidated, setIsEmailValidated] = useState(true);
    const [helperTextEmail, setHelperTextEmail] = useState('');
    const [displayHelperTextEmail, setDisplayHelperTextEmail] = useState(false);

    useEffect(() => {
        if (companyDetailsState && companyDetailsState.masterDetails.firstName !== '' &&
            companyDetailsState.masterDetails.surName !== '' &&
            companyDetailsState.masterDetails.email !== '') {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [companyDetailsState]);

    useEffect(() => {
        if (companyDetailsState && companyDetailsState.masterDetails && companyDetailsState.masterDetails.email && 
            companyDetailsState.masterDetails.firstName !== '' && companyDetailsState.masterDetails.surName !== '' &&
            companyDetailsState.masterDetails.email !== '') {
            if (!isEmailEmpty && !!isEmailValidated) {
                setIsButtonDisabled(false);
            } else if (!!isEmailEmpty || !isEmailValidated) {
                setIsButtonDisabled(true);
            }
        } else {
            setIsButtonDisabled(true);
        }
    }, [isEmailEmpty, isEmailValidated, companyDetailsState]);

    useEffect(() => {
        displayHelperTextEmailHandler();
    }, [isEmailValidated, isEmailEmpty]);

    const onChangeMasterFirstName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setCompanyDetailsState(prevState => ({
            ...prevState,
            masterDetails: {
                ...prevState.masterDetails,
                firstName: event.target.value,
            }
        }))
    };

    const onChangeMasterSurName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setCompanyDetailsState(prevState => ({
            ...prevState,
            masterDetails: {
                ...prevState.masterDetails,
                surName: event.target.value,
            }
        }))
    };

    const onChangeMasterEmail: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value !== '') {
            setIsEmailEmpty(false);
            if (validateEmail(event.target.value)) {
                setIsEmailValidated(true);
            } else {
                setIsEmailValidated(false);
            }
        } else {
            setIsEmailEmpty(true);
        }

        setCompanyDetailsState(prevState => ({
            ...prevState,
            masterDetails: {
                ...prevState.masterDetails,
                email: event.target.value,
            }
        }))
    };

    const onClickContinueToFinancialDetailsHandler = () => {
        pushToNextRoute(history, '/financial-details');
        dispatch(updateCompanyDetails(companyDetailsState));
    };

    const displayHelperTextEmailHandler = () => {
        if (isEmailEmpty) {
            setHelperTextEmail("Fill the Email address");
            setDisplayHelperTextEmail(true);
        }
        if (!isEmailEmpty && !isEmailValidated) {
            setHelperTextEmail("Fill a valid email format");
            setDisplayHelperTextEmail(true);
        }
        if (!isEmailEmpty && isEmailValidated) {
            setHelperTextEmail('');
            setDisplayHelperTextEmail(false);
        }
    };

    return (
        <StyledMasterDetails className="height-controller-wrapper">
            <Header title="Master details"/>
            <form>
                <GeneralTextField id="firstname"
                                  onChange={onChangeMasterFirstName}
                                  value={companyDetailsState ? companyDetailsState.masterDetails.firstName : ''}
                                  variant="filled"
                                  placeholder="First name"
                />
                <GeneralTextField id="surname"
                                  onChange={onChangeMasterSurName}
                                  value={companyDetailsState ? companyDetailsState.masterDetails.surName : ''}
                                  variant="filled"
                                  placeholder="Surname"
                />
                <GeneralTextField id="email"
                                  type="email"
                                  onChange={onChangeMasterEmail}
                                  value={companyDetailsState ? companyDetailsState.masterDetails.email : ''}
                                  required={true}
                                  helperText={displayHelperTextEmail && helperTextEmail}
                                  variant="filled"
                                  placeholder="Email address"
                />
            </form>
            <div className="actions-wrapper">
                <OutlinedButton className="back-button" onClick={() => {history.goBack()}}>Back</OutlinedButton>
                <PrimaryButton className="continue-button"
                               disabled={!!isButtonDisabled}
                               onClick={onClickContinueToFinancialDetailsHandler}>Continue</PrimaryButton>
            </div>
        </StyledMasterDetails>
    );
};
