import {
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, UPDATE_PRODUCTS_LIST
} from "./constants";


// GET ALL PRODUCTS
export const productsDetailsAttempt = () => ({
  type: GET_PRODUCTS_REQUEST,
});

export const productsDetailsSuccess = (data: any) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: { ...data},
});

export const productsDetailsFailure = (error: any) => ({
  type: GET_PRODUCTS_FAILURE,
  payload: error
});

export const updateProductsList = (data: any) => ({
  type: UPDATE_PRODUCTS_LIST,
  payload: data
});