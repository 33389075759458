import React, { FC, Fragment, useState } from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import { GeneralStyles } from "./styles";
import { SelectProduct } from "../../../pages/SelectProduct";
import { WelcomeDetails } from "../../../pages/WelcomeDetails";
import { TermsAndConditions } from "../../../pages/TermsAndConditions";
import Footer from "../../Footer";
import {MasterDetails} from "../../../pages/MasterDetails";
import {CompanyDetails} from "../../../pages/CompanyDetails";
import { FinancialDetails } from "../../../pages/FinancialDetails";
import { useEffect } from "react";

interface Props extends RouteComponentProps<any> {}
const initialHeight = window.innerHeight;

const Main: FC = () => {
  const [keyboard, setKeyboard] = useState(false);

  const apiBaseUrlByEnvironment = `${process.env.REACT_APP_BASE_API_URL}`;
  const environmentTypeName = apiBaseUrlByEnvironment.split('//')[1].split('.')[0];

  useEffect(() => {
    document.addEventListener("keypress", checkIfPressedKeyIsEnter);
    return () => {document.removeEventListener("keypress", checkIfPressedKeyIsEnter)};
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkIfKeyboardOpened);
    return () => {
      document.removeEventListener("resize", checkIfKeyboardOpened)
    };
  }, []);

  useEffect(() => {
    if (keyboard) {
      document.documentElement.style.setProperty('overflow', 'auto');
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'height=' + initialHeight + 'px, width=device-width, initial-scale=1.0');
      }
    } else {
      const metaViewport = document.querySelector('meta[name=viewport]');
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
      }
    }
  }, [keyboard]);

  const checkIfPressedKeyIsEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.querySelectorAll("input").forEach(el => el.blur());
    }
  };

  const checkIfKeyboardOpened = () => {
    // If the current active element is a text input, we can assume the soft keyboard is visible.
    if (document.activeElement) {
      document.activeElement.tagName === 'INPUT' ? setKeyboard(true) : setKeyboard(false);
    }
  };

  return (
    <Switch>
      <Fragment>
        <GeneralStyles theme={{keyboard}}>
          <Route exact path="/" component={SelectProduct}/>
          <Route exact path="/financial-details" component={FinancialDetails}/>
          <Route exact path="/welcome-details" component={WelcomeDetails}/>
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/company-details" component={CompanyDetails}/>
          <Route exact path="/master-details" component={MasterDetails}/>
          {(environmentTypeName === "test" || environmentTypeName === "dev") && <p className="env env-left">{environmentTypeName}</p>}
          {(environmentTypeName === "test" || environmentTypeName === "dev") && <p className="env env-right">{environmentTypeName}</p>}
          <Footer position={{keyboard}}/>
        </GeneralStyles>
      </Fragment>
    </Switch>
  );
};

export default Main;
