import {IState} from "../../store/models";

export const getCountryListData = (state: IState) => state.countryList.data;
export const getCountryListLoading = (state: IState) => state.countryList.loading;
export const getCountryListError = (state: IState) => state.countryList.error;

export const getCurrencyListData = (state: IState) => state.currencyList.data;
export const getCurrencyListLoading = (state: IState) => state.currencyList.loading;
export const getCurrencyListError = (state: IState) => state.currencyList.error;

export const getCompanyListData = (state: IState) => state.companyList.data;
export const getCompanyListLoading = (state: IState) => state.companyList.loading;
export const getCompanyListError = (state: IState) => state.companyList.error;
