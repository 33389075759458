import { put, takeLatest } from "redux-saga/effects";
import {
  GET_PRODUCTS_FAILURE, GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS
} from "./constants";
import {asyncRequest} from "../../api";
import {IAction, IProductsListState} from "./model";


export function* fetchProductsListSaga(action: IAction) {
  try {
    const getProductsList: IProductsListState = yield asyncRequest({
      config: {
        method: "GET",
      },
      endpoint: 'api/product',
    });


    yield put({ type: GET_PRODUCTS_SUCCESS, payload: getProductsList });
  } catch (error) {
    console.log(error);
    yield put({ type: GET_PRODUCTS_FAILURE, payload: error && error.message ? error.message : 'Unknown error' });
  }
}


export default  [
  takeLatest(GET_PRODUCTS_REQUEST, fetchProductsListSaga),
]
