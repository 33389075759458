import {ICompanyListState, ICountryListState, ICurrencyListState} from "./model";
import {
  GET_COMPANY_LIST_FAILURE,
  GET_COMPANY_LIST_REQUEST, GET_COMPANY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS, GET_CURRENCY_LIST_FAILURE,
  GET_CURRENCY_LIST_REQUEST,
  GET_CURRENCY_LIST_SUCCESS
} from "./constants";


const countryListInitialState: ICountryListState = {
  data: [],
  loading: false,
  error: null
};

export const getCountryListReducer = (state = countryListInitialState, action: any) => {
  switch (action.type) {
    case GET_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const currencyListInitialState: ICurrencyListState = {
  data: [],
  loading: false,
  error: null
};

export const getCurrencyListReducer = (state = currencyListInitialState, action: any) => {
  switch (action.type) {
    case GET_CURRENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_CURRENCY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const companyListInitialState: ICompanyListState = {
  data: [],
  loading: false,
  error: null
};

export const getCompanyListReducer = (state = companyListInitialState, action: any) => {
  switch (action.type) {
    case GET_COMPANY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_COMPANY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
