import styled from 'styled-components';
import paymeTheme from "../../../assets/styling/payme-theme";
import media from "../../../assets/styling/payme-media-queries";

const GeneralStyles = styled.div `
  height: 100%;
  font-size: ${paymeTheme.fontRemSizeBase};
  
  .height-controller-wrapper {
    padding-top: 4.375rem;
    padding-bottom: 8.75rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .height-controller-wrapper {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  
   .site-menu {
    .MuiSvgIcon-root {
      width: 1.875rem;
      height: 1.875rem;
    }
  }
  
  .helper-screen-description {
    color: ${paymeTheme.errorColor};
    font-size: ${paymeTheme.fontRemSizeLg};
    text-align: center;
    padding: ${paymeTheme.paddingRemLg};
  }
  
  .MuiTextField-root,
  .MuiFormControl-root {
    margin-bottom: 0.188rem;
  }
  
  .MuiFormLabel-root {
      font-weight: ${paymeTheme.fontRegular};
      font-family: "Exo 2"!important;
  }
  
  .MuiFilledInput-input {
    padding: ${paymeTheme.paddingRemXs};
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.8!important;
  }
  
  .MuiButtonBase-root.Mui-disabled {
    background-color: ${paymeTheme.lightGrey};
    color: ${paymeTheme.colorWhite};
  }
  
  form {
    margin-top: ${paymeTheme.listItemPadding};
    display: flex;
    flex-direction: column;
    justifyContent: center;
    align-items: center;
  }
  
  .actions-wrapper {
    padding: ${paymeTheme.paddingRemSm} 0;
    position: ${props => props.theme.keyboard === true ? 'absolute' : 'fixed'};
    bottom: 3.438rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    z-index: 1000;
    
    .back-button {
      margin-right: ${paymeTheme.paddingRemSm};
    }
    .continue-button {
      margin-left: ${paymeTheme.paddingRemSm};
    }
  }
  
  .MuiFormHelperText-root.Mui-required {
    color: ${paymeTheme.errorColor};
  }
  
  ${media.desktop} {
    max-height: 100%;
  }

  .actions-wrapper {
    bottom: 5.625rem;
  }

  .MuiNativeSelect-icon, .MuiCheckbox-root.Mui-checked, .MuiCheckbox-root {
    color: ${paymeTheme.floteColor};
  }

  .env {
    position: fixed;
    bottom: 5px;
    color: ${paymeTheme.colorYellow};
    z-index: 10000;
    font-size: ${paymeTheme.fontRemSizeNormal};
    text-transform: capitalize;

    &.env-right {
      right: ${paymeTheme.paddingRemMd};
    }

    &.env-left {
      left: ${paymeTheme.paddingRemMd};
    }
  }
  
`;

const StyledCardDetails = styled.div `
  &.height-controller-wrapper {
    align-items: center;
  }

  &.welcome-details {
    .card-details {
      background-color: transparent!important;
      color: ${paymeTheme.colorWhite};
    }
  }

  .card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: ${props => props.theme.isWelcomeDetails ? 0 : `2px solid ${paymeTheme.borderGrey}`};
    width: ${paymeTheme.widthLg};
    max-width: 400px;
    background-color: ${paymeTheme.colorWhite};
    margin-top: calc(30vh - 9.5rem);

    p {
      padding: 0 ${paymeTheme.paddingRemSm};
      font-size: ${paymeTheme.fontRemSizeH5};
      text-align: center;
    }
    
    p:last-child {
      margin-top: 0;
    }

    ${media.desktopMedium} {
      min-height: auto;
    }
  }

  .successful-registration-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
`;

export { GeneralStyles, StyledCardDetails } ;
